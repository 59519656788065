/**************************************************************************************************
 * Copyright (C) 2024 by Gohil Technologies Pvt Ltd. - All Rights Reserved.
 * Unauthorized copying or distribution of this file, via any medium is strictly prohibited.
 * Confidential.
 * Author : Kiran Baldaniya <kiran.baldaniya@gohiltech.com>
 **************************************************************************************************/

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { companyProfileSchema } from "../../common/Validator";
import { getCompanyProfile, saveCompanyProfile } from "../../api-wrapper/CompanyProfileApis";


function CreateCompanyProfile() {
	const navigate = useNavigate();
	const [profile, setProfile] = useState({
		organizationName: '',
		regNumber: '',
		panNumber : '',
		email : '',
		address : '',
		signatureRequired : true,
		logo : null
	});
	const [labelText,setLabelText]=useState("Select logo");
	const [imageData,setImageData]=useState(null);
	const [byteArray,setByteArray]=useState(null);
	const [errors, setErrors] = useState({});

	const handleSubmit = async (data) => {
		data.preventDefault();
		try {
            await companyProfileSchema.validate(profile, { abortEarly: false });
            await saveCompanyProfile(profile);
    		navigate('/company-profile');
        } catch (validationErrors) {
            const errors = {};
            validationErrors.inner.forEach(error => {
                errors[error.path] = error.message;
            });
            setErrors(errors);
        }
    };

	useEffect(() => {
		getCompanyProfile().then(response => {
			if(response){
			setProfile(response);
			setLabelText("Change logo");
			const base64String = `data:image/png;base64,`+ response['logo'];
			setImageData(base64String);
			const binaryString = atob(response['logo']);
			const uint8Array = new Uint8Array(binaryString.length);
			for (let i = 0; i < binaryString.length; i++) {
				uint8Array[i] = binaryString.charCodeAt(i);
			}
			setByteArray(uint8Array);}
		});
	},[]);

	const handleFieldChange = (e) => {
		const { id, value } = e.target;
		setProfile(prevProfile => {
			return {...prevProfile,[id]:value};
		  });
	  };
	 
	  const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
		  const reader = new FileReader();
		  
		  reader.onloadend = () => {
			setByteArray(new Uint8Array(reader.result));
			const base64String = `data:${file.type};base64,${arrayBufferToBase64(reader.result)}`;
			setImageData(base64String);
			setLabelText("Change logo");
		  };
		  
		  reader.readAsArrayBuffer(file);
		}
	  };
	  
	  const arrayBufferToBase64 = (buffer) => {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const len = bytes.byteLength;
		for (let i = 0; i < len; i++) {
		  binary += String.fromCharCode(bytes[i]);
		}
		return btoa(binary);
	  };

	const handleInput = (e) => {
        handleFieldChange(e);
    };

	useEffect(()=>{
	setProfile(prevProfile=>{return{...prevProfile,logo:byteArray}});
	},[byteArray]);

	return (
		<form className='create-user-form' onSubmit={handleSubmit} id='createCompanyProfileForm'>

			<div className='form-row'>
				<div className='form-field-container'>
					<label htmlFor='organizationName' id='organizationNameLabel'>Organization Name</label>
					<input
						className='user-box-input'
						type='text'
						id='organizationName'
						defaultValue={profile?.organizationName}
						onChange={handleFieldChange}  onInput={handleInput}/>
					 {errors.organizationName && <div className='error'>{errors.organizationName}</div>}
				</div>
				<div className='form-field-container'>
					<label htmlFor='regNumber' id='regNumberLabel'>Reg Number</label>
					<input
						className='user-box-input'
						type='text'
						id='regNumber'
						defaultValue={profile?.regNumber}
						onChange={handleFieldChange} onInput={handleInput}
					/>
					{errors.regNumber && <div className='error'>{errors.regNumber}</div>}
				</div>
			</div>

			<div className='form-row'>
				<div className='form-field-container'>
					<label htmlFor='panNumber' id='panNumberLabel'>PAN Number</label>
					<input
						className='user-box-input'
						type='text'
						id='panNumber'
						maxLength='10'
						defaultValue={profile?.panNumber}
						onChange={handleFieldChange} onInput={handleInput}
					/>
					{errors.panNumber && <div className='error'>{errors.panNumber}</div>}
				</div>
				<div className='form-field-container'>
					<label htmlFor='email' id='emailLabel'>Email</label>
					<input
						className='user-box-input'
						type='email'
						id='email'
						defaultValue={profile?.email}
						onChange={handleFieldChange}
					/>
					{errors.email && <div className='error'>{errors.email}</div>}
				</div>
			</div>

			<div className='form-row'>
				<div className='form-field-container'>
                    <label htmlFor='address' id='addressLabel'>Address</label>
                    <textarea
                        className='user-box-input'
                        id='address'
                        defaultValue={profile?.address}
						onChange={handleFieldChange}
						maxLength={255}
                    />
                    {errors.address && <div className='error'>{errors.address}</div>}
                </div>
				<div className='form-field-container'>
					<label htmlFor='signatureRequired' id='signatureRequiredLabel'>Signature Required</label>
					<select 
						id='signatureRequired'
						className='user-box-input' 
						value={profile?.signatureRequired}
						onChange={handleFieldChange}
					>
						<option value='true'>True</option>
						<option value='false'>False</option>
					</select>
					{errors.signatureRequired && <span className='error'>{errors.signatureRequired}</span>}
				</div>
				</div>
				<div className='form-field-container'>
				<label htmlFor='logo' className='inline-label'>Logo:</label>
					<div  className='label-container user-box-input'>
						{imageData && <img  src={imageData} width="25px" height="25px" ></img>}
					<label id="file-input-label" htmlFor="logo" className='inline-label button-label'>{labelText}</label>
					</div>
					<input
						type='file'
						id='logo'
						accept="image/png, image/jpeg"
						style={{display : "none"}}
						onChange={handleFileChange}
					/>
					
					{errors.logo && <div className="error">{errors.logo}</div>}
				</div>
			<br />
			<div>
				<button type='button' className='btn btn-secondary' onClick={() => navigate('/company-profile')} id='backButton'>
					Back
				</button>&nbsp;&nbsp;&nbsp;
				<button type='submit' className='btn btn-primary' id='submitButton'>
					Submit
				</button>
			</div>

		</form>   
	);
}


export default CreateCompanyProfile;